import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import Table from '../Table'
import { useTranslation } from 'react-i18next'
import { group } from 'd3-array'
import { ANTIGEN_LOOKUP_API } from '../../utils/const'

const SP32Table = ({ regions }) => {
  const { t } = useTranslation()
  // const allYears = []
  // const wrangleSP32Data = (data) => {
  //   const allAntigens = []
  //   const allData = Object.entries(data)
  //     .map(([key, value]) => {
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]
  //       if (!keysToExclude.includes(key) && value !== null) {
  //         const year = +key.slice(-4)
  //         let antigen = key.slice(0, -4)
  //         allAntigens.push(antigen)
  //         allYears.push(year)
  //         return { year, antigen, value: value * 100 }
  //       } else return null
  //     })
  //     .filter((item) => item)

  //   const antigens = Array.from(new Set(allAntigens))

  //   const formattedData = antigens.map((antigen) => {
  //     const antigenData = allData
  //       .filter((d) => d.antigen === antigen)
  //       .reduce((acc, d) => {
  //         acc[d.year] =
  //           d.value >= 0
  //             ? d.value.toFixed() + '%'
  //             : d.value === -2
  //             ? t('no data')
  //             : t('not measured')
  //         return acc
  //       }, {})
  //     return Object.assign(
  //       {
  //         antigen: t(antigen.toUpperCase())
  //       },
  //       antigenData
  //     )
  //   })
  //   return formattedData
  // }

  //const sp32Data = wrangleSP32Data(regions)

  let yrs = []
  const BASELINE_YEAR = 2019
  const dataMap = group(regions, (r) => r.DIMENSION2)
  const dataByAntigen = Array.from(dataMap.values())
  const formattedData = dataByAntigen.map((array) =>
    array.reduce((acc, d) => {
      if (d.YEAR !== BASELINE_YEAR) yrs.push(d.YEAR)
      acc[d.YEAR === BASELINE_YEAR ? 'Baseline' : d.YEAR] =
        d.VALUE_NUM.toFixed() + '%'
      acc['antigen'] = t(ANTIGEN_LOOKUP_API[d.DIMENSION2])
      return acc
    }, {})
  )
  const order = ['DTP3', 'MCV1', 'MCV2']

  const sp32Data = formattedData?.sort((a, b) =>
    order.indexOf(a.antigen) > order.indexOf(b.antigen) ? 1 : -1
  )
  const data = React.useMemo(() => sp32Data, [sp32Data])
  const years = Array.from(new Set(yrs)).sort((a, b) => (a > b ? 1 : -1))

  const yearHeaders = years.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })

  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: t('antigen'),
        accessor: 'antigen' // accessor is the "key" in the data
      },
      {
        Header: `${t('Baseline')} (${BASELINE_YEAR})`,
        accessor: 'Baseline' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])
  return (
    <Table
      data={data}
      columns={columns}
      noBoldTopRow={true}
      description={t(
        'A table that lists the percentage of DTP3, MCV1, and MCV2 coverage in the 20% of districts with the lowest coverage - by year.'
      )}
    />
  )
}

export default SP32Table
